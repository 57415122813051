import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Guitar.Services</h1>
        <p></p>
        <button>Learn More</button>
      </header>
      
      {/* Footer Section */}
      <footer className="App-footer">
        <p>Site made by Sneet</p>
      </footer>
    </div>
  );
}

export default App;
